<template>
  <!-- :class="getClass()" -->
	<div class="crtr-row" v-if="model.crtrInfo" :class="prop.class">
    <!-- 이름 -->
    <div class="crtr-nm text ellipsis" @click="onCrtrClck">
      <div>
        {{ model.crtrInfo.crtrNm ? model.crtrInfo.crtrNm : model.crtrInfo.dispName ? model.crtrInfo.dispName : "-" }}
      </div>
    </div>
    <!-- 레벨 아이콘 심플 -->
    <div class="levl-icon-wrap">
      <span class="levl-img-smal sm" :class="'levl-' + model.crtrInfo.crtrGrad"
            :style="'background-position-y: ' + model.icoPosY + 'px;'" />
    </div>
    <!-- 사진 -->
		<span class="prof-img-smal"
				 :style="`background-image: url('${model.crtrInfo.thumUrl ? model.crtrInfo.thumUrl : model.crtrInfo.phtoUrl ? model.crtrInfo.phtoUrl : emtyImgUrl }');`">
    </span>
    <span
      v-if="model.crtrInfo.ctry"
      class="flag float-none"
      :class="'flag-' + model.crtrInfo.ctry.toLowerCase()"
      :title="cnst.getCtryName(model.crtrInfo.ctry)">
    </span>
	</div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue'
import emtyImgUrl from '@/assets/img/icon/ic-no-prof-img.webp'
import { cnst_stor } from '@/stor/cnst_stor'
import { user_stor } from '@/stor/user_stor'
import { parseJwt } from '@/util/auth_func'
import { BrwsStrgKey } from '@/util/comn_cnst'

const DEFL_HEGH = 32
const cnst = cnst_stor()
const user = user_stor()
const emit = defineEmits(['onClck'])
const prop = defineProps(['data', 'hide', 'algn', 'noClck', 'class'])
const model = reactive({
	src: emtyImgUrl,
  crtrInfo: null,
  icoPosY: -2,
	wdth: 32,
	hegt: 32,
  fromJwt: false
})

// crtrId, crtrNm, crtrGrad, natnCtry, phtoUrl
watch(() => prop.data,
	(param) => {
    model.crtrInfo = param
    if (model.crtrInfo.crtrGrad) {
      const levl = parseInt(model.crtrInfo.crtrGrad.substring(4), 10)
      model.icoPosY = -1 * (levl - 1) * DEFL_HEGH - 2
    }
})

onMounted(() => {

  if (prop.data) {

    model.crtrInfo = prop.data
    model.crtrInfo.ctry = model.crtrInfo.natnCtry ?? model.crtrInfo.accsCtry
    if (model.crtrInfo.crtrGrad) {
      const levl = parseInt(model.crtrInfo.crtrGrad.substring(4), 10)
      model.icoPosY = -1 * (levl - 1) * DEFL_HEGH - 2
    }

  } else {

    const rawJwtStr = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
    if (!rawJwtStr) {
      return
    }

    let jwt = parseJwt(rawJwtStr)
    const crtrInfo = {}

    if (jwt['full_name']) {
      crtrInfo.dispName = jwt['full_name']
    }
    if (jwt['thumUrl']) {
      crtrInfo.thumUrl = jwt['thumUrl']
    }
    if (jwt['ctry']) {
      crtrInfo.ctry = jwt['ctry']
    }
    if (jwt['crtrGrad']) {
      crtrInfo.crtrGrad = jwt['crtrGrad']
      crtrInfo.showText = "Creator"
    }
    if (crtrInfo.crtrGrad) {
      const levl = parseInt(crtrInfo.crtrGrad.substring(4), 10)
      model.icoPosY = -1 * (levl - 1) * DEFL_HEGH - 2
    }
    model.crtrInfo = crtrInfo
    model.fromJwt = true
  }

})

function onCrtrClck() {
  if (prop.noClck && prop.noClck==='true') {
    return
  }
  if (model.crtrInfo.crtrId) {
    user.showCrtr(model.crtrInfo.crtrId)
  }
}

</script>

<style scoped>

.crtr-row {
  display: flex;
  height: fit-content;
  padding: 2px 0 0 0;
  font-size: 0.95rem;
  color: #595959;
}

.crtr-row > * {
  line-height: 26px;
}

.crtr-nm, .crtr-nm > *  {
  font-weight: 500;
  margin: 0;
  letter-spacing: -0.5px;
  font-size: 1.1rem;
}

.text {
  min-width: 26px;
  font-weight: 400;
}

.text > * {
  line-height: 1;
  vertical-align: bottom;
  letter-spacing: -0.5px;
}

.levl-img-smal {
  zoom: 1.5;
  overflow: hidden;
  position: absolute;
  width: 32px;
  height: 32px;
  left: -5px;
  top: -5px;
  background-position: center;
  background-size: cover;
  background-image: url("@/assets/img/icon/ic-rome-no-brdr-shdw.webp");
  background-position-x: 0;
  background-position-y: 0;
}

.fs-1r * {
  font-size: 1rem !important;
}

.fs-4 .text {
  font-size: 1.2rem;
  padding: 7px 0;
}

.fs-4 .text > * {
  color: #686868;
  line-height: 1;
}

.fw-bold .text {
  font-size: 1rem;
}

.prof-img-smal {
  min-width: 26px;
  width: 26px;
  height: 26px;
	border-radius: 5px;
	background-size: cover;
  display: inline-block;
  vertical-align: top;
	background-position: center;
	border: 1px solid #444;
  margin-left: 12px;
  cursor: pointer;
  margin-top: 3px;
}

.levl-icon-wrap {
  min-width: 26px !important;
  position: relative;
  width: 26px;
  height: 26px;
  margin: 1px 0 0 0;
}

.flag {
  margin-top: 4px;
  margin-left: 8px;
  height: 22px !important;
  width: 32px !important;
}

.crtr-row.text-navy-bold .text {
  color: #0D47A1 !important;
}

@media (max-width: 991px) {
  .from-jwt {
    justify-content: end;
    padding-right: 1rem;
  }
}

@media (min-width: 992px) {
  .from-jwt {
    height: 36px;
    width: 36px;
  }
  .from-jwt .prof-img-smal {
    width: 100%;
    height: 100%;
    min-width: 36px;
  }
  .from-jwt .text, .from-jwt .levl-icon-wrap, .from-jwt .flag, .from-jwt .crtr-nm {
    display: none;
  }
}
</style>
