const API_ROOT = "/api/v1/"
const CTNT_TYPE_JSON = "application/json;charset=utf-8"
const ResCd = {
  SUCCESS: 200,
  REGISTERED: 201,
  BROKEN: 204,
  BAD_RQST: 400,
  NO_AUTH: 401,
  NOT_FOUND: 404,
  GONE: 410,
  NO_PERN_TEEN: 411,
  NO_PERN_ADLT: 412,
  SERVER_ERR: 500,
  TOKN_EXPD: 600,
  USER_EXIST_SAME_PVDR: 900,
  USER_EXIST_DIFF_PVDR: 901,
  REG_WITH_OTHER: 902,
  MAIL_NOT_VRFY: 903,
  USER_NOT_FOUND: 904,
  PIN_NOT_MATCH: 905
}

const PERM_ADMN = "FUPM0008"

const CrdtAmt = {
  GEN_FLUX_SCHN: 1,
  UPSC_4X: 3,
  REMOVE_BG: 3
}

const EditType = {
  GEN_FLUX_SCHN: "FLUX0000",
  ENLG_BG: "enlarge-bg",
  REMV_BG: "remove-bg",
  UPSC_4X: "upscale_4x",
  REMV_OBJ: "remove-object",
  RPLC_OBJ: "replace-object",
}

const BrwsStrgKey = {
  FO_JWT: 'tf',           // session storage
  BO_JWT: 'tb',           // session storage
  USER_PROF_IMG: 'pi',    // session storage
  USER_NAME: 'ni',        // session storage
  NOTI_DATA: 'n2',        // session storage
  FIRST_SIGN_IN: 'ac1',   // session storage
  STOP_TOKN_RQST: '_st8', // session storage
  URL_BEFR_RGST: 'rg3',   // session storage

  FNGRPRNT_PLUS: 'tp', // local storage
  USER_LOCATION: 'tl',       // local storage
  USER_TIMEZONE: 'tz',       // local storage
  IS_FROM_VUE: 'isfv',       // local storage

  TOGL_OPEN_README_AT_RGST: 'tg2',
  ALL_WORK_CNT: '_awc1',
  PIN_AUTH_INFO: 'p3'
}

let spldHorzOptn = {
  // type: 'slide',
  slideFocus: true,
  autoWidth: true,
  fixedHeight: '100%',
  breakpoints: {
    640: {
      fixedHeight: '80px'
    }
  },
  perPage: 12,
  perMove: 1,
  arrows: false,
  keyboard: 'global',
  rewind: false,
  gap: '1px',
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ltr',
  direction: 'ltr',
  cover: false,
  isNavigation: true,
  paginationKeyboard: true
}

let spldVertOptn = {
  slideFocus: false,
  fixedWidth: '100px',
  autoHeight: true,
  heightRatio: 12,
  perPage: 12,
  perMove: 1,
  keyboard: 'global',
  rewind: false,
  gap: '1px',
  updateOnMove: true,
  pagination: true,
  paginationDirection: 'ttb',
  direction: 'ttb',
  cover: false,
  isNavigation: true
}

const KeyStrg = {
  SAFE_SRCH: '_ds'
}

let comnPage, stckPage

function ready(callback){
  // in case the document is already rendered
  if (document.readyState!=='loading') {
    callback()
  } else if (document.addEventListener) {
    // modern browsers
    document.addEventListener('DOMContentLoaded', callback)
  } else document.attachEvent('onreadystatechange', function(){
    // IE <= 8
    if (document.readyState==='complete') callback()
  })
}

const ComnPage = class {

  init() {
    const that = this
    let menu = document.querySelectorAll(".left-nav .nav-link")
    const pathname = location.pathname
    menu.forEach((it) => {
      let url = it.getAttribute("href")
      if (pathname.startsWith(url)) {
        it.classList.add("router-link-active")
      }
    })
    const btnMy = document.getElementById("btn-my")
    if (btnMy) {
      btnMy.addEventListener("click", function(e) {
        location.href = "/view/user/dashboard"
      })
    }
    const fileForm = document.getElementById("file-form")
    if (fileForm) {
      fileForm.addEventListener("submit", function(e) {
        console.log('[onFileSubmit]', e)
        e.preventDefault()
      })
    }
    const fileUpld = document.getElementById("file-upld")
    if (fileUpld) {
      fileUpld.addEventListener("change", function(e) {
        console.log('[fileChange]', e)
        that.onUpldFileChng(e)
      })
    }
    const btnClosUpld = document.getElementById("btn-clos-upld")
    if (btnClosUpld) {
      btnClosUpld.addEventListener("click", function(e) {
        document.getElementById("file-upld-mdal").classList.add("d-none")
      })
    }
    const btnUpldSbmt = document.getElementById("btn-upld-sbmt")
    if (btnUpldSbmt) {
      btnUpldSbmt.addEventListener("click", function(e) {
        console.log('[btnUpldSbmt]', e)
        that.doUpldMlty()
      })
    }
    const btnMoblTogl = document.getElementById("btn-mobl-togl")
    if (btnMoblTogl) {
      btnMoblTogl.addEventListener("click", function(e) {
        console.log('[btnUpldSbmt]', e)
        that.toglMoblMenu()
      })
    }
  }

  toglMoblMenu() {
    const toglMenu = document.getElementById("icon-mobl-togl")
    const toglDraw = document.getElementById("navbarToggleExternalContent")
    toglMenu.classList.toggle("open")
    toglDraw.classList.toggle("show")
    if (toglMenu.classList.contains("open")) {
    }
  }

  onSubmit() {
    return false
  }

}

const StckPage = class {

  INIT_NVBR_Y_POS = 0
  INIT_SRCH_Y_POS = 16
  TOP_NVBR_Y_POS = -50
  BTOM_SRCH_Y_POS = -75

  listWrap = null
  topNav = null
  srchBar = null
  listLodr = null
  detlLodr = null
  reqLock = false
  scrlY = 0
  prevSrchBarY = 0
  srchBarY = 0
  prevNavBarY = 0
  navBarY = 0
  openFromList = false
  scrlTrgrMrgn = 0
  pageData = {}
  grupId = null
  b62Id = null
  wdth = []
  hegt = []
  imgListInfo = []
  citePreTxt = []
  current = null
  currMain = null
  currIndx = -1
  splideMain = null
  splideThum = null
  fullScrnElem = null
  thumbnails = null
  zoomistMap = {}
  itvlId = -1
  extendCnt = 0

  constructor() {}

  initListEvntLsnr() {

    console.log('[initListEvntLsnr]')
    const that = this
    window.addEventListener('popstate', this.onPopState, false)
    window.addEventListener('keyup', this.onKeyUp, false)
    window.addEventListener('backbutton', this.onBackPres, false)
    // document.addEventListener('scroll', this.setOnScroll, false)

    this.addItemClickListn()
    this.topNav = document.getElementById("top-nav")
    this.srchBar = document.getElementById("float-srch-bar")
    this.getPageDataAt(document.querySelectorAll(".indx-item")[0])
    this.listWrap = document.getElementById("scrl-wrap")
    this.listLodr = document.getElementById("main-loader")

    const srchInpt = document.getElementById("srch-inpt")
    const btnSrchEntr = document.getElementById("btn-srch-entr")
    const btnRemvKywd = document.getElementById("btn-remv-kywd")
    const btnShuf = document.getElementById("btn-shfl")

    if (srchInpt) {
      srchInpt.addEventListener("keypress", function(e) {
        // If the user presses the "Enter" key on the keyboard
        console.log("event.key", e.key)
        if (e.key === "Enter") {
          stckPage.doFreeSrch(srchInpt.value)
          e.preventDefault()
          e.stopPropagation()
        }
      })
    }
    if (btnSrchEntr) {
      btnSrchEntr.addEventListener("click", function(e) {
        stckPage.doFreeSrch(srchInpt.value)
      })
    }
    if (btnRemvKywd) {
      btnRemvKywd.addEventListener("click", function(e) {
        srchInpt.value = ''
        stckPage.doFreeSrch('')
      })
    }
    if (btnShuf) {
      btnShuf.addEventListener("click", function(e) {
        stckPage.doRndmSrch()
      })
    }

    const tokn = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
    if (tokn && tokn.length > 9 && this.itvlId === -1) {
      this.itvlId = setInterval(
        that.chckToknExprThenSignOut
        , (60 * 1000))
    }

    this.hideListLoader()
  }

  async appendNextPage(e, forcPageIdx) {
    // append
    const that = this
    if (e && this.scrlTrgrMrgn===0) {
      this.scrlTrgrMrgn = parseInt(e.srcElement.scrollHeight/3*2, 10)
    }
    // console.log(`[CHECK-APPEND] ${parseInt(e.srcElement.scrollingElement.scrollTop, 10)} >= ${parseInt(e.srcElement.scrollingElement.scrollHeight/3, 10)}`)
    if ((parseInt(e.srcElement.scrollTop, 10) > (parseInt(e.srcElement.scrollHeight, 10) - this.scrlTrgrMrgn))
      && this.reqLock===false) {

      this.reqLock = true
      this.showListLoader()
      setTimeout(function() {
        that.reqLock = false
        that.hideListLoader()
      }, 10000)

      console.warn('[RQST-APND]')
      try {
        const urlParams = new URLSearchParams(location.search)
        const q = urlParams.get('q')
        let kywd = ''
        if (q) {
          kywd = `q=${q}`
        }
        // pageIdx, hex, q
        let quryStr = new URLSearchParams(`pageIdx=${this.pageData.nextPageIdx}&hex=${this.pageData.lastHex}${kywd}`).toString()
        console.log(`[quryStr] ${quryStr}`)
        let url = `/api/v1/pblc/stck/html?${quryStr}`
        if (forcPageIdx) {
          url = `/api/v1/pblc/stck/html`
        }
        let init = {}
        const tf = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
        if (tf) {
          init = {
            headers: {
              Authorization: `Bearer ${tf}`
            }
          }
        }
        const data = await fetch(url, init)
        const list = await data.text()
        console.log('[PAGE-LIST][HTML]', list.substring(0, 255))
        stckPage.listWrap.insertAdjacentHTML('beforeend', list)
        const elem = document.querySelectorAll(".indx-item")
        const lastElem = elem[elem.length-1]
        this.getPageDataAt(lastElem)
        this.addItemClickListn()
      } catch (e) {
        console.error('[ERR][CALL-NEXT-PAGE]', e.message)
      } finally {
        this.reqLock = false
        this.hideListLoader()
      }
    }
  }

  addItemClickListn() {
    const that = this
    document.querySelectorAll("#list-inner-html .item .btn-thum").forEach((it) => {
      it.addEventListener('click', function(e) {
        console.log('[ITEM-CLCK-EVNT]', it.getAttribute('href'))
        e.stopImmediatePropagation()
        const link = it.getAttribute('href') // attributes.href.nodeValue('href')
        that.showListLoader()
        that.showImgDetlPart(link)
        e.preventDefault()
        return false
      })
    })
  }

  initScrollPage() {
    this.prevSrchBarY = this.INIT_SRCH_Y_POS
    this.srchBarY = this.INIT_SRCH_Y_POS
    this.prevNavBarY = this.INIT_NVBR_Y_POS
    this.navBarY = this.INIT_NVBR_Y_POS
  }

  lstnBackPresChckByKey(e) {
    console.log('[KEY-UP]', location.pathname, e.keyCode)
    if (e.keyCode===8) {
      stckPage.closMdal()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  onKeyUp(e) {
    console.log('[KEY-UP]', location.pathname, e.keyCode)
    if (e.target.getAttribute("id")==="srch-inpt") {
      return true
    }
    if (e.keyCode === 8) {
      // PC 백버튼
      stckPage.closMdal()
      e.preventDefault()
      e.stopPropagation()
      return false
    } else if (e.keyCode === 38 || e.keyCode === 40) {
      return false
    } else {
      return true
    }
  }
  onBackPres(e) {
    console.log('[POP-STAT-AND-BACK-PRES]', location.pathname, e.keyCode)
    stckPage.closMdal(e)
  }

  showListLoader() {
    console.log('[showListLoader]')
    if (!this.listLodr) {
      this.listLodr = document.getElementById("main-loader")
    }
    if (this.listLodr) {
      this.listLodr.classList.remove("d-none")
    }
  }

  hideListLoader() {
    console.log('[hideListLoader]')
    if (!this.listLodr) {
      this.listLodr = document.getElementById("main-loader")
    }
    if (this.listLodr) {
      this.listLodr.classList.add("d-none")
    }
  }

  showDetlLoader() {
    console.log('[showDetlLoader]')
    if (!this.detlLodr) {
      this.detlLodr = document.getElementById("detl-non-block-loader")
    }
    if (this.detlLodr) {
      this.detlLodr.classList.remove("d-none")
    }
  }

  hideDetlLoader() {
    console.log('[hideDetlLoader]')
    if (!this.detlLodr) {
      this.detlLodr = document.getElementById("detl-non-block-loader")
    }
    if (this.detlLodr) {
      this.detlLodr.classList.add("d-none")
    }
  }

  doFreeSrch(kywd) {
    console.log("doFreeSrch", kywd)
    let url = ''
    if (kywd.length > 0) {
      url = location.pathname + "?q=" + encodeURIComponent(kywd)
    } else {
      url = location.pathname
    }
    this.showListLoader()
    console.log("url", url)
    location.href = url
  }

  doRndmSrch() {
    let url = location.pathname
    let query = "?s=1"
    if (url.indexOf('?')>-1) {
      query = "&s=1"
    }
    console.log("url", url)
    this.showListLoader()
    location.href = location.pathname + query
  }

  initDetlEvntLsnr() {

    console.log('[initDetlEvntLsnr]')
    const that = this
    const modal = document.getElementById("img-detl-mdal")
    if (modal) {
      modal. classList.remove("d-none")
    }
    this.detlLodr = document.getElementById("detl-non-block-loader")
    const imgInfoList = document.querySelectorAll(".img-list-info")
    this.wdth = []
    this.hegt = []
    this.imgListInfo = []
    if (imgInfoList) {
      imgInfoList.forEach((it) => {
        const wdthHegtStr = it.getAttribute("data-img-list-info")
        const wdthHegtArr = wdthHegtStr.split(",")
        this.wdth.push(wdthHegtArr[0])
        this.hegt.push(wdthHegtArr[1])
        const imgAdtnInfo = it.getAttribute("data-adtn-info")
        const imgAdtnInfoArr = imgAdtnInfo.split("|")
        this.imgListInfo.push({
          wdth: wdthHegtArr[0],
          hegt: wdthHegtArr[1],
          b62Id: imgAdtnInfoArr[0],
          pblcId: imgAdtnInfoArr[1],
          taskName: imgAdtnInfoArr[2]
        })
      })
    }
    this.splideMain = new Splide('#main-splide', {
      type: 'slide',
      rewind: false,
      pagination: false,
      arrows: true
    })
    this.splideThum = new Splide('#thum-splide', spldHorzOptn)
    this.splideMain.sync(this.splideThum)
    this.splideMain.mount()
    this.splideThum.mount()

    this.splideMain.on('move', function(index) {
      if (that.current) {
        that.current.classList.remove('is-active')
      }
      let thumbnail = that.thumbnails[that.splideMain.index]
      if (thumbnail) {
        thumbnail.classList.add('is-active')
        that.current = thumbnail
      }
    })

    this.splideMain.on('active', function(slide) {
      console.log('[ACTIVE]', slide.index, slide)
      that.currMain = slide.slide
      that.onSpldActv(slide.index)
    })

    this.splideMain.on('inactive', function(slide) {
      console.log('[INACTIVE] ', slide.index)
      that.removePrevZoom(slide.index)
    })

    let index = 0, cnt = 0
    const thumSpld = document.querySelectorAll('.thum-spld')
    const b62Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    if (thumSpld) {
      that.thumbnails = Array(thumSpld.length)
      thumSpld.forEach((it) => {
        that.thumbnails[cnt] = it
        const dataId = it.getAttribute('data-id')
        if (dataId === b62Id) {
          index = cnt
        }
        cnt++
      })
    }

    if (index>0) {
      that.splideMain.go(index)
    } else {
      that.onSpldActv(0)
    }
    that.addNewZoom(index)
    that.preventPinchZoom()

    window.addEventListener('popstate', this.onPopState, false)
    window.addEventListener('keyup', this.onKeyUp, false)
    window.addEventListener('backbutton', this.onBackPres, false)

    this.fullScrnElem = document.getElementById('img-ctnt-wrap')
    const btnFullScrn = document.getElementById('btn-full-scrn')
    const btnMinmScrn = document.getElementById('btn-minm-scrn')
    const btnShre = document.getElementById('btn-shre')
    const btnQuot = document.getElementById('btn-quot')
    const btnLike = document.getElementById('btn-like')
    const btnPay = document.getElementById('btn-pay')
    const btnPopClos = document.getElementById("btn-pop-clos")

    if (btnFullScrn) {
      btnFullScrn.addEventListener('click', function(e) {
        console.log('[btnFullScrn] click')
        that.setFullScrn(true)
      })
    }
    if (btnMinmScrn) {
      btnMinmScrn.addEventListener('click', function(e) {
        that.setFullScrn(false)
      })
    }
    if (btnShre) {
      btnShre.addEventListener('click', function(e) {
        stckPage.onShre()
      })
    }
    if (btnQuot) {
      btnQuot.addEventListener('click', function(e) {
        that.onCiteCtnt()
      })
    }
    if (btnLike) {
      btnLike.addEventListener('click', function(e) {
        that.onLike()
      })
    }
    if (btnPay) {
      btnPay.addEventListener('click', function(e) {
        // that.onPay()
        that.toast('Feature under maintenance!')
      })
    }
    if (btnPopClos) {
      btnPopClos.addEventListener("click", function(e) {
        that.closMdal(e)
      })
    }
    this.hideListLoader()
    this.hideDetlLoader()

  }

  onSpldActv(indx) {
    let currSlid
    if (!this.currMain) {
      currSlid = document.querySelectorAll(".zoomist-container")[0]
    } else {
      currSlid = this.currMain
    }
    const wdth = currSlid.getAttribute('data-wdth')
    const hegt = currSlid.getAttribute('data-hegt')
    const pixl = currSlid.getAttribute('data-pixl')
    document.getElementById('wdth-hegt').innerHTML = '(' + wdth + 'x' + hegt + 'px,&nbsp;' + pixl + ')'
    document.getElementById('slct-indx').innerText = (indx + 1)
  }

  onPopState(e) {
    console.log('[onPopState]', e)
    stckPage.closMdal()
  }

  setFullScrn(yn) {
    console.log('[IMG-DETL-PANE] set full scrn to', yn)
    const fullScrnElem = document.getElementById('img-ctnt-wrap')
    // 내장 객체임
    if (yn===false) { // if (document.fullscreenElement) {
      // exitFullscreen is only available on the Document object.
      console.log('Leaving fullscreen mode.')
      fullScrnElem.classList.remove('fullscreen')
      document.exitFullscreen()
    } else {
      fullScrnElem.classList.add('fullscreen')
      fullScrnElem.requestFullscreen()
    }
  }

  removePrevZoom(idx) {
    if (this.zoomistMap[`${idx}`]) {
      this.zoomistMap[`${idx}`].reset()
      this.zoomistMap[`${idx}`].destroy(true)
      this.zoomistMap[`${idx}`] = null
    }
  }

  addNewZoom(idx) {
    const inxStr = (idx + 1) < 10 ? `0${(idx + 1)}` : `${(idx + 1)}`
    this.zoomistMap[`${idx}`] = new Zoomist(`#main-splide-slide${inxStr}`, {
      maxScale: 4,
      slider: false,
      zoomer: false,
      bounds: true,
      draggable: true
    })
  }

  preventPinchZoom() {
    document.addEventListener('touchmove', this.__blockPinchZoom, { passive: false })
  }

  __blockPinchZoom(e) {
    if (e.touches.length > 1) {
      e.preventDefault() // preventDefault를 사용하려면 passive를 false로 해줘야합니다.
    }
  }

  toglKywdShow() {
    this.isKywdShow = !this.isKywdShow
    if (this.isKywdShow) {
      document.getElementById('kywd-wrap-tr').classList.remove('d-none')
      document.getElementById('btn-fold').classList.remove('rotate-180')
    } else {
      document.getElementById('btn-fold').classList.add('rotate-180')
      document.getElementById('kywd-wrap-tr').classList.add('d-none')
    }
  }

  addBookmark(e) {
    if (window.sidebar && window.sidebar.addPanel) { // Firefox <23
      window.sidebar.addPanel(document.title,window.location.href,'');
    } else if(window.external && ('AddFavorite' in window.external)) { // Internet Explorer
      window.external.AddFavorite(location.href,document.title);
    } else if(window.opera && window.print || window.sidebar && ! (window.sidebar instanceof Node)) { // Opera <15 and Firefox >23
      /**
       * For Firefox <23 and Opera <15, no need for JS to add to bookmarks
       * The only thing needed is a `title` and a `rel="sidebar"`
       * To ensure that the bookmarked URL doesn't have a complementary `#` from our trigger's href
       * we force the current URL
       */
      e.target.attr('rel', 'sidebar').attr('title', document.title).attr('href', window.location.href);
      return true;
    } else { // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D
      alert('You can add this page to your bookmarks by pressing ' + (navigator.userAgent.toLowerCase().indexOf('mac') != - 1 ? 'Command/Cmd' : 'CTRL') + ' + D on your keyboard.');
    }
    // If you have something in the `href` of your trigger
    e.stopImmediatePropagation()
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  onShre() {
    const that = this
    let path = location.href
    console.log('path', path)
    window.navigator.clipboard.writeText(path)
      .then(() => {
        const msg = '<i class="fa-regular fa-copy me-2 text-primary"></i>The content URL to share<br/>' +
          'has been copied<br />to the clipboard'
        that.toast(msg)
      })
  }

  onLike() {

    // 로그인 / 비로그인모두 처리
    const tf = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
    const isSignedIn = tf && tf.length > 9
    console.log('[PARSE-JWT] ' + this.parseJwt())

    fetch(location.origin + '/api/v1/prvt/pmpt/like', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tf}`,
        'tp': localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
      },
      body: JSON.stringify({
        grupId: this.grupId, ctntType: 'image'
      })
    }).then((res) => {
      const data = res.data
      if (data.code === 200) {
        const toast = document.getElementById('tost-mdal')
        let msg = ''
        if (data.rslt === true) {
          msg = '<i class="fa-solid fa-thumbs-up me-2 text-primary"></i> Added to like!'
        } else {
          msg = '<i class="fa-regular fa-trash-xmark me-2 text-danger"></i> Removed from like!'
        }
        document.getElementById('tost-msg').innerHTML = msg
        toast.classList.add('show')
        setTimeout(function() {
          toast.classList.remove('show')
        }, 3000)
      }
    })
      .catch((e) => {
        console.error('e', JSON.stringify(e))
      })
      .finally(() => {
      })

  }

  toast(msg) {
    const toast = document.getElementById('tost-mdal')
    document.getElementById('tost-mesg').innerHTML = msg
    toast.classList.add('show')
    setTimeout(function() {
      toast.classList.remove('show')
    }, 2500)
  }

  initCiteActn() {
    this.citePreTxt[0] = "Image"
    this.citePreTxt[1] = "Image by {data.detl.crtrNm}"
    this.citePreTxt[2] = "{data.detl.crtrNm}, &quot;{data.detl.titlMod}&quot;,"
    const relsDate = document.getElementById("rels-date")
    if (relsDate) {
      relsDate.innerText = this.isoDateToShortYear4('{data.detl.aprvDttm}')
    }
    this.citeTextChng(1)
    this.grupId = '{data.detl.grupId}'
  }

  citeTextChng(idx) {
    document.getElementById('cite-pre-txt').innerHTML = this.citePreTxt[idx]
    let html = document.getElementById('quot-wrap').innerHTML.toString()
    document.getElementById('cite-html').innerText = html
  }

  onCiteCtnt() {
    console.log('main', this.currMain)

    // console.log('this.splideMain', this.splideMain.splides[0])
    const that = this
    if (!this.currMain) {
      this.currMain = document.querySelectorAll(".zoomist-container")[0]
    }
    const midlUrl = this.currMain.getAttribute("data-midl-url")
    const b62Id = this.currMain.getAttribute("data-id")
    const crtrNm = this.currMain.getAttribute("data-crtr-nm")
    const citeHtml = document.getElementById('cite-html')
    const url = location.href.substring(location.href.lastIndexOf("/") + 1) + b62Id
    document.getElementById('url-link').setAttribute('href', url)
    document.getElementById('img-to-quot').setAttribute('src', midlUrl)
    document.getElementById('cite-mdal').classList.remove('d-none')
    // 공백전체 str.replace(/\s*/g, "")
    // 줄바꿈 전체 str.replace(/\n/g, "")
    citeHtml.value = that.prettyHtml(document.getElementById("quot-wrap").innerHTML)
    const btnSm = document.getElementById('btn-cite-sm')
    const btnMd = document.getElementById('btn-cite-md')
    const btnCiteCopy = document.getElementById('btn-cite-copy')
    const citePreTxt = document.getElementById('cite-pre-txt')
    const btnClosCite = document.getElementById('btn-clos-cite')

    console.log('onCiteContent', midlUrl)
    btnSm.addEventListener("click", function(e) {
      citePreTxt.innerText = "Image"
      citeHtml.value = that.prettyHtml(document.getElementById("quot-wrap").innerHTML)
    })
    btnMd.addEventListener("click", function(e) {
      citePreTxt.innerText `Image by ${crtrNm}`
      citeHtml.value = that.prettyHtml(document.getElementById("quot-wrap").innerHTML)
    })
    btnCiteCopy.addEventListener("click", function(e) {
      that.onCiteCopyClck()
    })
    btnClosCite.addEventListener("click", function(e) {
      document.getElementById('cite-mdal').classList.add('d-none')
    })
  }

  prettyHtml(rawHtml) {
    return rawHtml.replace(/\n/g, "").replaceAll("<img", "\n<img").replaceAll("<span", "\n<span").replaceAll("<a", "\n<a").replaceAll("<div", "\n<div").replaceAll("</span>", "</span>\n").replaceAll("</div>", "\n</div>").replaceAll("</a>", "\n</a>").replace("\n", "").replace("          ", "")
  }

  onCiteCopyClck() {
    let html = document.getElementById('cite-html').value.toString()
    const toast = document.getElementById('tost-mdal')
    const msg = '<i class="fa-regular fa-copy me-2"></i>The content HTML code<br/>' +
      'has been copied<br />to the clipboard'
    document.getElementById('tost-mesg').innerHTML = msg
    window.navigator.clipboard.writeText(html)
      .then(() => {
        toast.classList.add('show')
        setTimeout(function() {
          toast.classList.remove('show')
        }, 3000)
      })
  }

  onCnfmCnclClck() {
    document.getElementById('alert-diag').classList.add('d-none')
  }

  parseJwt() {

    const token = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
    if (!token || token.length < 10) {
      return null
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    try {
      let message = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
      return JSON.parse(message)
    } catch (e) {
      console.error('[ParseJwt]', e.message)
      return null
    }
  }

  goToSignIn() {
    location.href = '/view/sign/in?r=' + encodeURIComponent(location.pathname)
  }

  closMdal(e) {
    const url = location.pathname
    let midlUrl = url.indexOf('/free/') > -1 ? 'free' : 'stock'
    const leftPane = document.getElementById("img-detl-left-pane")
    if (leftPane) {
      const openFromList = leftPane.getAttribute("data-open-from-list")
      console.log('[openFromList]', openFromList)
      if (openFromList==='true') {
        history.replaceState({}, undefined, `/view/${midlUrl}/images`)
        document.getElementById("img-detl-mdal").classList.add("d-none")
      } else {
        location.href = `/view/${midlUrl}/images`
      }
    } else {
      location.href = `/view/${midlUrl}/images`
    }

    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  async showImgDetlPart(link) {

    const that = this
    if (!link) {
      console.warn("[STCK-DETL] No Link URL found")
      return
    }
    const pathArr = link.split("/")
    const b62Id = pathArr[pathArr.length - 1]
    console.log(`[STCK-DETL][B62-ID/PATH] ${b62Id} / ${link}`)
    this.b62Id = b62Id
    const tf = sessionStorage.getItem(BrwsStrgKey.FO_JWT)
    const tp = localStorage.getItem(BrwsStrgKey.FNGRPRNT_PLUS)
    let init = {
      headers: {}
    }
    if (tf) {
      init.headers["Authorization"] = `Bearer ${tf}`
    }
    if (tp) {
      init.headers["tp"] = tp
    }
    const rawRes = await fetch(
      `/api/v1/pblc/stock/images/${b62Id}/part`,
      init
    )
    console.log('[rawRes]', JSON.stringify(rawRes))
    if (rawRes.status===ResCd.SUCCESS) {
      const html = await rawRes.text()
      if (html) {
        that.hideListLoader()
        document.getElementById("img-detl-mdal").classList.remove("d-none")
        document.getElementById("img-modal-body").innerHTML = html
        that.openFromList = true
        setTimeout(function() {
          stckPage.initDetlEvntLsnr()
          history.pushState({}, undefined, link)
        }, 100)
      }
    } else {
      console.log('[RES-HTML] get HTML part err')
      this.hideListLoader()
    }

  }

  // data-totl-page-idx, data-totl-item-cnt, data-has-next, data-last-hex,
  getPageDataAt(htmlElem) {
    console.log('[getPageDataAt]', htmlElem)
    if (htmlElem) {
      const pageData = {
        totlItemCnt: parseInt(htmlElem.getAttribute("data-totl-item-cnt"), 10),
        totlPageIdx: parseInt(htmlElem.getAttribute("data-totl-page-idx"), 10),
        nextPageIdx: parseInt(htmlElem.getAttribute("data-next-page-idx"), 10),
        hasNext: htmlElem.getAttribute("data-has-next") === 'true',
        lastHex: htmlElem.getAttribute("data-last-hex")
      }
      this.pageData = pageData
    }
  }

  setOnScroll(e) {
    // console.log('[SCROLL] e', e)
    this.scrlY = e.srcElement.scrollTop // + scrollHeight
    try {
      if (this.topNav) {
        this.updtNvbrY(this.scrlY)
      }
      if (this.srchBar) {
        this.updtSrchY(this.scrlY)
      }
    } catch (e) {
      console.error('[ERR][ON-SCRL]', e.message)
    }
    console.log("[pageData]", JSON.stringify(stckPage.pageData))
    if (stckPage.pageData.hasNext && stckPage.pageData.lastHex) {
      stckPage.appendNextPage(e)
    } else if (!stckPage.pageData.hasNext && !stckPage.allLoad) {
      stckPage.allLoad = true
      stckPage.listWrap.insertAdjacentHTML('beforeend', '<h3 class="mt-5 fw-bold text-primary w-100 text-center">[ Sign in to see more content ]</h3>')
    }
  }

}

function initScript() {
  console.log('[ready]')
  if (location.hostname.endsWith("gen8.id") && !location.hostname.endsWith("dev.gen8.id")) {
    console.log = function () {}
  }
  comnPage = new ComnPage()
  comnPage.init()
  stckPage = new StckPage()
  if (location.pathname.endsWith('/images') || location.pathname.endsWith('/images/')) {
    // 목록 화면
    stckPage.initListEvntLsnr()
    // stckPage.initScrollPage()
  } else if (location.pathname.indexOf('/stock/images/')>-1 && location.pathname.length > 25) {
    // 상세 화면
    stckPage.initDetlEvntLsnr()
  }
}

ready(function(){
  initScript()
})

export { StckPage, ComnPage, initScript }