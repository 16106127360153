<template>
  <div class="modal" tabindex="-1">
    <div class="modal-dialog modal-xs round">
      <div class="modal-content d-flex justify-content-between">
        <div class="modal-header">
          <img src="/aset/img/icon/logo-generated-navbar.webp" class="logo-icon rounded me-auto" alt="Navigation bar logo of Genereted service" />

        </div>
        <div class="modal-body flex-grow-1 px-2">
          <h5 class="text-center" v-html="mdal.getCnfmMsg"></h5>
        </div>

        <!-- footer button area -->
        <div class="btn-area d-flex">

            <button
              type="button"
              class="btn btn-outline-light me-auto shadow-md"
              data-bs-dismiss="modal"
              @click="mdal.onCnfmCnclBtnClck()"
              v-html="
                mdal.getCnfmBtnTxts[1]
                  ? mdal.getCnfmBtnTxts[1]
                  : '<i class=\'fa-solid fa-xmark-large me-2\'></i>Cancel'
              "
            ></button>
            <button
              v-if="mdal.getCnfmBtnTxts.length > 2 && mdal.getCnfmBtnTxts[2]"
              type="button"
              class="btn btn-outline-light shadow-md"
              @click="mdal.onCnfmMidlBtnClck()"
              v-html="mdal.getCnfmBtnTxts[2]"
            >
            </button>

            <button
              type="button"
              class="btn btn-primary btn-confirm w-100 shadow-md"
              @click="mdal.onCnfmCnfmBtnClck()"
              v-html="
                mdal.getCnfmBtnTxts[0]
                  ? mdal.getCnfmBtnTxts[0]
                  : '<i class=\'fa-solid fa-check me-2\'></i>OK'
              "
            ></button>
        </div>
        <!-- footer button area -->

      </div>
    </div>
  </div>
</template>

<script setup>
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
</script>

<style scoped>
h5 {
  line-height: 32px;
}

.modal-body {
  padding: 0.6rem 0 1.2rem;
}

.btn-area {
  padding: 0 1rem 1rem;
}

.btn-area .btn {
  font-size: 17px;
  line-height: 1;
  padding: 5px;
  flex: 1 1 auto !important;
}

.btn-area .btn + .btn {
  margin-left: 0.5rem;
}

.btn-area .btn.btn-confirm {
  max-width: 50%;
  width: 100%;
  min-width: 100px;
  padding-right: 10px;
}

.pos-abs-top-right {
  padding: 0.6rem;
}

.pos-abs-top-right svg {
  height: 2rem;
}
</style>
