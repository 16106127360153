import { defineStore } from 'pinia'

export const mdal_stor = defineStore({
  id: 'mdal',
  state: () => ({
    mainLodrYn: false,
    nonBlckLodnYn: false,
    showAlrtYn: false,
    showCnfmYn: false,
    lodrThme: <string | null>null,
    lodrMesg: <string | null>null,
    tostMsg: <string | null>null,
    lodrNonBlckMsg: <string | null>null,
    alrtBtnText: <string | null>null,
    alrtMdalMsg: <string | null>null,
    alrtBtnClbk: <Function | null>null,
    smallTextAlrt: false,
    cnfmMdalMsg: <string | null>null,
    cnfmBtnText: Array<string | null>(null, null, null),
    cnfmCnfmBtnClbk: <Function | null>null,
    cnfmCnclBtnClbk: <Function | null>null,
    cnfmMidlBtnClbk: <Function | null>null,
    evntShow: <boolean | null>null,
    fcbkChsrVsbl: false,
    scrlWrap: null,
    imgDetlData: null,
    fcbkUserObjt: <object | undefined>undefined
  }),
  getters: {
    isLoading: (state) => {
      return state.mainLodrYn
    },
    isNonBlckLodn: (state) => {
      return state.nonBlckLodnYn
    },
    getLodrMesg: (state) => {
      return state.lodrMesg
    },
    getLodrNonBlckMsg: (state) => {
      return state.lodrNonBlckMsg
    },
    getLodrThem: (state) => {
      return state.lodrThme
    },
    isFcbkChsrVsbl: (state) => {
      return state.fcbkChsrVsbl
    },
    getFcbkUser: (state) => {
      return state.fcbkUserObjt
    },
    getTostMsg: (state) => {
      return state.tostMsg
    },
    showAlrt: (state) => {
      return state.showAlrtYn
    },
    getAlrtBtnNm: (state) => {
      return state.alrtBtnText
    },
    getAlrtMsg: (state) => {
      return state.alrtMdalMsg
    },
    getAlrtCallback: (state) => {
      return state.alrtBtnClbk
    },
    isSmallTextAlrt: (state) => {
      return state.smallTextAlrt
    },
    showCnfm: (state) => {
      return state.showCnfmYn
    },
    getCnfmMsg: (state) => {
      return state.cnfmMdalMsg
    },
    getCnfmBtnTxts: (state) => {
      return state.cnfmBtnText
    },
    getCnfmBtnClbk: (state) => {
      return state.cnfmCnfmBtnClbk
    },
    getImgDetlData: (state) => {
      return state.imgDetlData
    },
    showEvnt: (state) => {
      const evntShow = localStorage.getItem('show-evnt')
      if (evntShow) {
        if (evntShow === 'false') {
          const now = new Date().getTime() / 1000
          const until = parseInt(evntShow, 10)
          if (now > until) {
            localStorage.removeItem('show-evnt')
            state.evntShow = true
          }
        } else {
          localStorage.removeItem('show-evnt')
        }
      }
      if (state.evntShow != null) {
        return state.evntShow
      }
      if (evntShow) {
        state.evntShow = JSON.parse(evntShow)
      } else {
        state.evntShow = true
      }
      return state.evntShow
    }
  },
  actions: {
    toast(tostMsg: string) {
      this.tostMsg = tostMsg
    },
    showMainLodr(lodrThme: string | undefined, lodrMesg: string | undefined) {
      this.mainLodrYn = true
      if (lodrThme) {
        this.lodrThme = lodrThme
      } else {
        this.lodrThme = 'black'
      }
      if (lodrMesg) {
        this.lodrMesg = lodrMesg
      } else {
        this.lodrMesg = 'Loading'
      }
    },
    stopMainLodr() {
      this.mainLodrYn = false
      this.lodrThme = null
    },
    alert(msg: string, alrtBtnClbk: Function | undefined, alrtBtnText: string | undefined, isSmallText: boolean | undefined) {
      this.alrtMdalMsg = msg
      if (alrtBtnText) {
        this.alrtBtnText = alrtBtnText
      } else {
        this.alrtBtnText = null
      }
      if (alrtBtnClbk) {
        this.alrtBtnClbk = alrtBtnClbk
      } else {
        this.alrtBtnClbk = null
      }
      if (isSmallText) {
        this.smallTextAlrt = isSmallText
      } else {
        this.smallTextAlrt = false
      }
      this.showAlrtYn = true
    },
    onAlrtBtnClck() {
      if (this.alrtBtnClbk) {
        this.alrtBtnClbk()
      }
      this.hideAlrt()
    },
    confirm(
      cnfmMdalMsg: string,
      cnfmCnfmBtnClbk: Function | undefined,
      cnfmBtnText?: string | undefined,
      cnfmCnclBtnClbk?: Function | undefined,
      cnclBtnText?: string | undefined,
      midlBtnText?: string | undefined,
      cnfmMidlBtnClbk?: Function | undefined
    ) {
      this.cnfmMdalMsg = cnfmMdalMsg
      if (cnfmCnfmBtnClbk) {
        this.cnfmCnfmBtnClbk = cnfmCnfmBtnClbk
      }
      if (cnfmCnclBtnClbk) {
        this.cnfmCnclBtnClbk = cnfmCnclBtnClbk
      } else {
        this.cnfmCnclBtnClbk = null
      }
      if (cnfmMidlBtnClbk) {
        this.cnfmMidlBtnClbk = cnfmMidlBtnClbk
      } else {
        this.cnfmMidlBtnClbk = null
      }
      if (cnfmBtnText) {
        this.cnfmBtnText[0] = cnfmBtnText
      } else {
        this.cnfmBtnText[0] = null
      }
      if (cnclBtnText) {
        this.cnfmBtnText[1] = cnclBtnText
      } else {
        this.cnfmBtnText[1] = null
      }
      if (midlBtnText) {
        this.cnfmBtnText[2] = midlBtnText
      } else {
        this.cnfmBtnText[2] = null
      }
      this.showCnfmYn = true
    },
    onCnfmCnfmBtnClck() {
      if (this.cnfmCnfmBtnClbk) {
        this.cnfmCnfmBtnClbk()
      }
      this.hideCnfm()
    },
    onCnfmMidlBtnClck() {
      if (this.cnfmMidlBtnClbk) {
        this.cnfmMidlBtnClbk()
      }
      this.hideCnfm()
    },
    onCnfmCnclBtnClck() {
      if (this.cnfmCnclBtnClbk) {
        this.cnfmCnclBtnClbk()
      }
      this.hideCnfm()
    },
    hideAlrt() {
      this.showAlrtYn = false
    },
    hideCnfm() {
      this.showCnfmYn = false
    },
    hideEvnt() {
      localStorage.setItem('show-evnt', 'false')
      localStorage.setItem(
        'hide-untl',
        (Math.floor(new Date().getTime() / 1000) + 86400).toString()
      )
      this.evntShow = false
    },
    setFcbkChsrVsbl(showYn: boolean, fcbkUserObjt: object | undefined) {
      this.fcbkChsrVsbl = showYn
      if (fcbkUserObjt) {
        this.fcbkUserObjt = fcbkUserObjt
      }
    },
    openImgDetlPopUp(param: object | null) {
      console.log('openImgDetlPopUp', JSON.stringify(param))
      this.imgDetlData = param
    },
    closMdal(e) {
      const url = location.pathname
      const midlUrl = url.indexOf('/free/') > -1 ? 'free' : 'stock'
      const leftPane = document.getElementById("img-detl-left-pane")
      if (leftPane) {
        const openFromList = leftPane.getAttribute("data-open-from-list")
        console.log('[openFromList]', openFromList)
        const modal = document.getElementById("stck-img-detl-html")
        if (modal) {
          modal.innerHTML = '<div id="detl-non-block-loader" class="loading-inner position-absolute white">'
            + '<img src="/aset/img/icon/icon-loading-white.webp" alt="[Generated] Progress Loader | www.gen8.id"/></div>'
        }
        if (openFromList==='true') {
          history.replaceState({}, undefined, `/view/${midlUrl}/images`)
          document.getElementById("img-detl-mdal").classList.add("d-none")
        } else {
          location.href = `/view/${midlUrl}/images`
        }
      } else {
        location.href = `/view/${midlUrl}/images`
      }

      if (e) {
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }
})
