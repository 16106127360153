<template>
  <!--begin::Modal - 내정보-->
  <div class="modal" tabindex="-1">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-sm round">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal body-->
        <div class="modal-body">
          <div
            v-if="model.user"
            id="photo-frame"
            class="photo-frame text-center align-middle inner-shadow"
            :style="'background-image: url(\'' + model.user.phtoUrl + '\')'"
          >
            <img class="prof-img-deco svg-shadow-sm" src="/aset/img/brand/icon-win-flag-right.png" />
          </div>

          <table class="table white">
            <colgroup>
              <col style="width: 168px" />
              <col style="width: auto" />
            </colgroup>

            <tbody v-if="model.user">
              <tr class="my-name bb-solid bt-solid">
                <td colspan="2" class="text-end">
                  <crtr-info-full-horz :data="model.user" :hide="['prfx']" class="fs-3 justify-content-end" noClck="true" />
                </td>
              </tr>
              <tr class="bb-solid">
                <td colspan="2" class="my-1 border-end-0 inner-shadow-sm self-itro-wrap">
                  <div class="user-itro text-dark text-start mb-2"
                    v-html="model.user.selfItro?model.user.selfItro.replace('\n', '<br/>'):'-'">
                  </div>
                  <div v-if="model.user.crtrSpctList" class="d-block">
                    <span
                      v-for="(item, idx) in model.user.crtrSpctList"
                      :key="idx"
                      class="badge text-bg-secondary h-slim mb-0"
                      >{{ item }}</span
                    >
                  </div>
                </td>
              </tr>

              <tr class="bb-solid">
                <th>
                  <div class="d-flex user-id th-innr">
                    <div class="flex-grow-1">ID</div>
                    <div
                      class="sns-mark text-gray d-flex"
                      :class="model.user.authPvdr.length === 1 ? 'wd-22' : ''"
                    >
                      <span v-if="model.user.authPvdr.indexOf('e') > -1">
                        <i class="fa-regular fa-envelope"></i>
                      </span>
                      <span v-if="model.user.authPvdr.indexOf('f') > -1">
                        <i class="fa-brands fa-square-facebook"></i>
                      </span>
                      <span v-if="model.user.authPvdr.indexOf('g') > -1">
                        <i class="fa-brands fa-google"></i>
                      </span>
                    </div>
                  </div>
                </th>
                <td>{{ model.user.userId ?? "-" }}</td>
              </tr>

              <tr class="bb-solid">
                <th>
                  <ul class="ul-flag d-flex th-innr">
                    <li class="legl-nm flex-grow-1">E-Mail</li>
                  </ul>
                </th>
                <td>{{ model.user.emal ?? "-" }}</td>
              </tr>

              <tr class="bb-solid">
                <th>
                  <ul class="ul-flag d-flex th-innr">
                    <li class="legl-nm flex-grow-1">Age Verified by</li>
                    <li>
                      <span v-if="model.user.userVrfy.adltVrfy" class="text-gray"
                        ><i class="fa-solid fa-shield-check text-primary"
                      /></span>
                    </li>
                  </ul>
                </th>
                <td class="vrfy">
                  <div v-if="!model.user.userVrfy.adltVrfy">-</div>
                  <div v-if="model.user.userVrfy.adltVrfy" class="d-flex justify-content-end">
                    <span v-if="model.user.userVrfy.paplVrfy" class="badge ic-vendor me-0">
                      <img src="@/assets/img/icon/btn-papl.webp" />
                    </span>
                    <span v-if="model.user.userVrfy.strpVrfy" class="badge ic-vendor me-0">
                      <img src="@/assets/img/icon/btn-strp.webp" />
                    </span>
                  </div>
                </td>
              </tr>

              <tr class="bb-solid">
                <th class="py-0">
                  <div class="d-flex row-tier">
                    <div class="flex-grow-1">Reputation</div>
                    <div class="badge-wrap d-none">
                      <div class="repu-cnt badge btn-outline-primary mx-auto my-0 bg-white">0</div>
                    </div>
                  </div>
                </th>
                <td class="py-0 star-wrap">
                  <i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i
                  ><i class="fa-regular fa-star"></i><i class="fa-regular fa-star"></i
                  ><i class="fa-regular fa-star"></i>
                </td>
              </tr>
            </tbody>
          </table>

          <!--Since area -->
          <div class="btn-area px-3 pt-0 pb-3 d-flex bg-white-leather snce-area">
            <div v-if="model.user" class="flex-grow-1 pt-2 text-end fs-7 fw-medium">
              Since {{ isoDateToShortYear4(model.user.rgstDttm) }}
            </div>
          </div>
          <!--Since area -->
        </div>

        <spnr-no-block v-if="model.loading" />

      </div>
      <!--end::Modal content-->

      <!--end::Modal body-->
      <btn-close-top-right @onCloseClick="user.showMyInfo(false)" />

      <!-- bottom fixed button area -->
      <div class="btn-pos-fix-btm p-3 d-flex">
        <a @click="goToMyPage" class="btn btn-lg btn-outline bg-white shadow-md w-40 ellipsis">
          <div class="icon">
            <i class="fa-regular fa-file-circle-info"></i>
          </div>
          <div class="text">My Page</div>
        </a>
        <a @click="user.cnfmApiSignOutThenGoMain()" class="btn btn-lg btn-primary btn-outline shadow-md flex-grow-1 w-60">
          <div class="icon">
          <i class="fa-solid fa-person-running-fast" />
          </div>
          <div class="text">Sign Out</div>
        </a>
      </div>
      <!-- bottom fixed button area -->

    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - 내정보-->
</template>

<script setup>
import { onBeforeUnmount, onMounted, reactive } from "vue"
import { user_stor } from "@/stor/user_stor"
import emtyUrl from "@/assets/img/icon/ic-no-prof-img-big.webp"
import BtnCloseTopRight from "@/view/btns/BtnCloseTopRight.vue"
import axis_cstm from "@/util/axis_cstm"
import { API_ROOT, ResCd } from "@/util/comn_cnst"
import { isoDateToShortYear4 } from '@/util/comn_util'
import SpnrNoBlock from '@/view/pgrsAlrt/SpnrNoBlock.vue'
import router from '@/rout'
import CrtrInfoFullHorz from '@/view/cpnt/CrtrInfoFullHorz.vue'
import { preventBgScroll, releaseBgScroll } from '@/util/ui_evnt_util'

const emit = defineEmits(["closeModal"])
const user = user_stor()

const model = reactive({
  loading: true,
  user: null,
  leglNm: "",
  phto: null,
  adltVrfys: [],
  emalVrfy: false
})

onMounted(() => {
  getUserData()
  if (document.body.offsetWidth < 992) {
    preventBgScroll()
  }
})

onBeforeUnmount(() => {
  if (document.body.offsetWidth < 992) {
    releaseBgScroll()
  }
})

function getUserData() {
  axis_cstm()
    .get(API_ROOT + "prvt/my")
    .then((res) => {
      const data = res.data
      if (data.code === ResCd.SUCCESS) {
        // console.log("rslt", JSON.stringify(data.rslt))
        model.user = data.rslt
        if (!model.user.phtoUrl) {
          model.user.phtoUrl = emtyUrl
        }
      }
    })
    .catch((err) => {
      console.log(`${err.name} ${err.code} ${err.message}`)
    })
    .finally(() => {
      model.loading = false
    })
}

function goToMyPage() {
  router.push('/view/user/dashboard')
  user.showMyInfo(false)
}
</script>

<style scoped>
.modal-dialog {
  z-index: 3;
  overflow: hidden;
}

.modal-body {
  background-image: url(@/assets/img/bg/bg-leather-white-rect-small.webp);
  background-repeat: repeat;
}

.modal-content {
  overflow-y: auto;
}

.btn-pos-fix-btm .btn {
  display: flex;
  font-weight: 500;
  padding-left: 12px;
  font-size: 1.35rem;
}

.btn-pos-fix-btm .btn + .btn {
  margin-left: 1rem;
}

.btn-pos-fix-btm .btn .text {
  margin-left: 0.5rem;
}

.badge.h-slim {
  line-height: 1;
  text-shadow: -1px 0 rgba(0,0,0,0.3), 0 1px rgba(0,0,0,0.3), 1px 0 black, 0 -1px rgba(0,0,0,0.3);
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
  border-radius: 9px;
}
.self-itro-wrap {
  padding: 7px 10px;
}
.photo-frame {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 380px;
}

.photo-frame.empty {
  background-size: 70%;
}

.th-innr > *:nth-child(2):not(.sns-mark) {
  width: 27px;
  text-align: center;
}

th svg,
.sns-mark span svg {
  height: 16px;
  margin-top: 4px;
}

.sns-mark span + span {
  margin-left: 0.5rem;
}

.table > * > * > * {
  height: 40px;
}

.table > * > * > *[colspan="2"] {
  border-top: 1px solid #222;
}

tr > *:last-child {
  border-right: none !important;
}

.my-name > * {
  padding: 6px;
}

.table tbody tr:last-child {
  border-bottom: 1px solid #1e3e68;
}

tbody th { padding-left: 0.5rem; }
tbody td { padding-right: 0.5rem; }

.prof-img-deco {
  position: absolute;
  right: -36px;
  bottom: 0;
  width: 128px;
}
.user-itro {
  line-height: 1.5;
}
.legl-nm {
  line-height: 24px;
  margin-right: 9px;
}

.user-id svg {
  height: 22px;
}

.vrfy {
  color: #1e3e68;
  padding: 3px 0.5rem;
}

.ic-vendor {
  padding: 3px 6px;
  margin-bottom: 0;
}

.ic-vendor > img {
  height: 19px;
}

.badge-wrap {
  min-width: 27px;
  position: relative;
}

.badge-wrap .badge {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 23px;
}

.star-wrap svg {
  color: rgb(0, 169, 204);
}

.wd-22 {
  width: 22px;
}

.snce-area {
  padding-bottom: 90px !important;
}

@media (min-width: 768px) {
  .modal-sm .modal-dialog {
    width: 400px;
    height: 770px;
    max-width: calc(100% - 6rem);
    max-height: calc(100% - 4rem);
  }
}

@media (max-width: 767.98px) {
  .modal-sm .modal-dialog {
    height: calc(100% - 2.6rem);
    top: 2.6rem;
    bottom: 0;
    transform: translate(-50%, 0);
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .modal-sm .modal-dialog .modal-content {
    padding-bottom: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
</style>
