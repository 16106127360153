<template>
	<!--begin::Modal - 내정보-->
	<div class="modal" tabindex="-1">
		<!--begin::Modal dialog-->
		<div class="modal-dialog modal-sm">
			<!--begin::Modal content-->
			<div class="modal-content">
				<!--begin::Modal body-->
				<div class="modal-body">

					<div class="modal-header">
						<h3 class="mx-3 mt-3 px-1">
							<div class="d-flex">
								<i class="fa-duotone fa-flag-checkered me-2"></i><div>Report a Problem</div>
							</div>
						</h3>
					</div>

					<div class="btn-grp-resn px-3 mt-3">

						<div class="btn-group-vertical w-100" role="group" aria-label="Basic radio toggle button group">
							<template v-for="(item, idx) in model.RPRS" :key="idx">
								<input type="radio" class="btn-check" name="btn-resn"
                       :id="item.code" autocomplete="off" v-model="model.resn"
                       :value="item.code" :checked="item.code===model.resn">
								<label :for="item.code" class="btn btn-white text-start">{{ item.name }}</label>
							</template>
						</div>

						<small class="text-dark mt-2">※ Selling prompts for celebrities or licensed characters is allowed.</small>

						<div class='form-floating message-wrap mt-2'>
							<textarea id='floatingInput' v-model='model.message' autocomplete='new-password'
										 class='form-control inner-shadow-xs'
												maxlength='300' placeholder='userid@hompage.com' rows="3"></textarea>
							<label for='floatingInput' class="text-secondary">Additional message, if have.</label>
						</div>

            <a class="btn btn-register btn-primary shadow-smd1 d-flex mt-3 py-2" @click="onAddImageUploadClick" >
              <img class="ic-upld" src="@/assets/img/icon/ic-img-upld.webp" />
              <div class="btn-desc">Click to attach an image to explain the situation</div>
            </a>
            <div v-if="model.fileName" class="fs-8 mt-2">File: {{model.fileName}}</div>
					</div>

					<!--begin::Menu item-->
					<div class="btn-area pt-0 d-flex bg-white-leather">

						<div class="btn-pos-fix-btm px-2 py-2 d-flex w-60">
							<a @click="reportProblem"
								 :class="model.disabled?'disabled':''"
								class="ms-3 btn btn-lg btn-warning btn-outline shadow-md flex-grow-1 d-flex">
                <i class="fa-regular fa-siren-on me-2"></i><div>Report</div>
							</a>
						</div>

					</div>

					<!--end::Menu item-->
				</div>
				<!--end::Modal body-->
				<btn-close-top-right @onCloseClick="emit('closeModal')" />

			</div>
			<!--end::Modal content-->
		</div>
		<!--end::Modal dialog-->
	</div>
	<!--end::Modal - 내정보-->

  <form class='file-form d-none' method='post' @submit.prevent='formSubmit'>
    <input
        ref='addFiles'
        accept='.jpeg,.jpg,.png,.webp,.avif,.gif,.jfif'
        class='form-control'
        multiple
        name='files'
        type='file'
        @change='onAddImageFileChanged'
    />
  </form>

</template>

<script setup>
import {onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue'
import { user_stor } from '@/stor/user_stor'
import { cnst_stor } from '@/stor/cnst_stor'
import emtyUrl from '@/assets/img/icon/ic-no-prof-img-big.webp'
import BtnCloseTopRight from '@/view/btns/BtnCloseTopRight.vue'
import axis_cstm from "@/util/axis_cstm"
import { API_ROOT, ResCd, WletVrfyStat } from '@/util/comn_cnst'
import { preventBgScroll, releaseBgScroll } from '@/util/ui_evnt_util'
import { mdal_stor } from '@/stor/mdal_stor'

const mdal = mdal_stor()
const emit  = defineEmits(['closeModal'])
const consts = cnst_stor()
const user = user_stor()
const prop = defineProps(['grupId', 'crtrId'])
const addFiles = ref()

const model = reactive({
  resn: null,
	RPRS: consts.getCodeGrup('RPRS'),
  message: null,
	disabled: true,
  uploadFileList: [],
  fileName: '',
})

watch(() => model.resn
, (newVal, oldVal) => {
	console.log("newVal/oldVal", newVal, oldVal)
	if (newVal && newVal.length===8) {
		model.disabled = false
    if (oldVal && oldVal.length===8) {
      document.getElementById(oldVal).classList.remove('active')
    }
    document.getElementById(newVal).classList.add('active')
	}
})

function isChecked(code) {
  const chcked = (code===model.resn)
  console.log('chcked', chcked, code, model.resn)
  return chcked
}

function reportProblem() {
  if (user.isSignedIn) {
    mdal.confirm('Sign in is required.<br/>Would you like to sign in?'
      , function() {
        emit('closeModal')
				mdal.closImgDetlPopUp()
        const imgPath = model.d ? "?d=" + model.d : location.search ? location.search : ""
        location.href = "/view/sign/in?r=" + encodeURIComponent(`/view/stock/images/${model.grup.grupId}${imgPath}`)
      },
      '<i class=\'fa-solid fa-check me-2\'></i> Sign in')
  } else {
    mdal.confirm('Do you really want to Report?'
      , function() {
        const resnData = model.RPRS.find((item) => item.code === model.resn);

        const formData = new FormData();
        for (let i = 0; i < model.uploadFileList.length; i++) {
          formData.append('files', model.uploadFileList[i]);
        }
        formData.append('grupId', prop.grupId)
        formData.append('crtrId', prop.crtrId)
        formData.append('code', resnData.code)
        formData.append('name', resnData.name)
        if (model.message) {
          formData.append('msg', model.message)
        }
        mdal.showMainLodr()
        axis_cstm()
          .post(API_ROOT + 'pblc/pmpt/rprt', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            const data = res.data
            if (data.code === ResCd.SUCCESS) {
              mdal.toast("Report has been submitted!")
              emit('closeModal')
            }
          })
          .catch((err) => {
            console.error('err', err)
          })
          .finally(() => {
            mdal.stopMainLodr()
          })
      }, '<i class=\'fa-solid fa-check me-2\'></i> Report')

  }
}

function getUserData() {
  if (user.isSignedIn) {
    axis_cstm()
      .get(API_ROOT + 'prvt/my')
      .then((res) => {
        const data = res.data
        if (data.code === ResCd.SUCCESS) {
          console.log('rslt', JSON.stringify(data.rslt))
          model.user = data.rslt
          if (!model.user.phtoUrl) {
            model.user.phtoUrl = emtyUrl
          }
          if (model.user.adltVrfys.indexOf(WletVrfyStat.VRFIED_PAPL)>-1) {
            model.adltVrfys.push('<i class="fa-brands fa-paypal"></i>')
          }
          if (model.user.adltVrfys.indexOf(WletVrfyStat.VRFIED_STRP)>-1) {
            model.adltVrfys.push('<i class="fa-brands fa-stripe"></i>')
          }
          model.emalVrfy = data.rslt.emalVrfy
        }
      })
      .catch((err) => {
        console.log(`${err.name} ${err.code} ${err.message}`)
      })
      .finally(() => {
      })
  }
}

function onAddImageUploadClick() {
  addFiles.value.value = ''
  addFiles.value.click()
}

function onAddImageFileChanged(e) {
  // 1) check validation
  const selectedFiles = e.target.files

  if (selectedFiles.length < 1) {
    console.log('no file selected')
    return
  }

  model.uploadFileList = selectedFiles
  console.log('selectedFiles', model.uploadFileList)
  let fileNames = []
  for (let i = 0; i < selectedFiles.length; i++) {
    fileNames.push(selectedFiles[i].name)
  }
  model.fileName = fileNames.join(', ')

  let totlFileCnt = selectedFiles.length
  let overFileCnt = 0

  let fileCnt = 0
  Array.from(selectedFiles).forEach(() => {
    /*if (item.size >= 262144) {*/
    // bigger than 512x512
    if (fileCnt < 12) {
      fileCnt++
    } else {
      overFileCnt++
    }
  })

  let mesg = ''
  if (overFileCnt > 0) {
    mesg += mesg.length > 0 ? '<br/>' : ''
    const imgStr = overFileCnt > 1 ? 's' : ''
    mesg += `Image${imgStr} exceeding 12 are not uploaded.`
  }
  if (overFileCnt > 0) {
    const imgStr = fileCnt > 1 ? 's' : ''
    mesg += `<br/>Total ${fileCnt}/${totlFileCnt} image${imgStr} are uploaded.`
    mdal.toast(mesg)
  }
}

onMounted(() => {
	getUserData()
	preventBgScroll()
})

onBeforeUnmount(() => {
	releaseBgScroll()
})
</script>

<style scoped>
.modal-body {
	height: fit-content;
	background-image: url(@/assets/img/bg/bg-leather-white-rect-small.webp);
	background-repeat: repeat;
}

.modal-header svg {
	margin-top: 6px;
}

th svg, .sns-mark span svg {
	height: 16px;
	margin-top: 4px;
}

.sns-mark span + span { margin-left: 0.5rem; }

.table > * > * > *[colspan="2"] {
	border-top: 1px solid #222;
}

.table tbody tr:last-child {
		border-bottom: 1px solid #1e3e68;
}

.user-id svg {
	height: 22px;
}

.btn-grp-resn .btn {
	font-size: 0.9rem;
	padding-top: 0;
	padding-bottom: 0;
  height: 36px;
	line-height: 31px !important;
}

.btn-register {
  height: 45px;
}
.btn-desc {
  line-height: 1;
  text-align: start;
  margin: auto 10px;
}

.btn-check.active + label {
  background-color: rgb(0, 169, 204);
  border: 2px solid #595959;
}

</style>
