const LOCALHOST_V4 = '127.0.0.1'
const LOCALHOST_V6_1 = '::1'
const LOCALHOST_V6_2 ='0:0:0:0:0:0:0:1'

const API_ROOT = import.meta.env.VITE_APP_API_BASE_PATH
const ITEM_PER_PAGE_FO_IMG_LIST = 150
const INIT_FOOTER_Y = 0
const MAX_FOOTER_Y = -50

const AD_TEST_YN = import.meta.env.VITE_APP_ENV!=='production'?'on':''
const CDNR_MAX_RESO = 4194304
const CDNR_MAX_RESO_STR = '4,194,304'

const ResCd = {
  SUCCESS: 200,
  REGISTERED: 201,
  BROKEN: 204,
  BAD_RQST: 400,
  NO_AUTH: 401,
  NOT_FOUND: 404,
  GONE: 410,
  NO_PERN_TEEN: 411,
  NO_PERN_ADLT: 412,
  SERVER_ERR: 500,
  TOKN_EXPD: 600,
  USER_EXIST_SAME_PVDR: 900,
  USER_EXIST_DIFF_PVDR: 901,
  REG_WITH_OTHER: 902,
  MAIL_NOT_VRFY: 903,
  USER_NOT_FOUND: 904,
  PIN_NOT_MATCH: 905
}

const ImgGenPvdr = {
  FLUX_SCHN: 'FLUX0000',
  FLUX_DEV: 'FLUX0001',
  FLUX_DEV_EXT: 'FLUX0002',
  KLING: 'KLNG0000',
  LUMA: 'LUMA0000',
  SDXL_LGHT: 'SDXL0000',
  SDXL_1_0: 'SDXL0001',
  DALLE_3: 'DALE0003',
  DREAM_8: 'DMSP8LCM'
}

const VidoGenPvdr = {
  LUMA_DREAM: "GTVD0010",
  KLING_AI: "GTVD0020",
  RUNWAY_GEN3: "GTVD0030"
}

const CrdtAmt = {
  GEN_FLUX_SCHN: 1,
  GEN_DREAM_8: 2,
  GEN_SDXL_1_0: 2,
  GEN_KLING_IMG: 2,
  GEN_LUMA_IMG: 2,
  GEN_DALLE_3: 3,
  UPSC_4X: 3,
}

const EditType = {
  GEN_FLUX_SCHN: "FLUX0000",
  GEN_DALLE_3: "DALE0003",
  GEN_DREAM_8: "DMSP8LCM",
  GEN_SDXL_1_0: "SDXL0001",
  GEN_KLING_IMG: "KLNG0000",
  GEN_LUMA_IMG: "LUMA0000",
  ENLG_BG: "enlarge-bg",
  REMV_BG: "remove-bg",
  UPSC_4X: "upscale_4x",
  REMV_OBJ: "remove-object",
  RPLC_OBJ: "replace-object",
}

const BrwsStrgKey = {
  FO_JWT: 'tf',           // session storage
  BO_JWT: 'tb',           // session storage
  USER_PROF_IMG: 'pi',    // session storage
  USER_NAME: 'ni',        // session storage
  NOTI_DATA: 'n2',        // session storage
  FIRST_SIGN_IN: 'ac1',   // session storage
  STOP_TOKN_RQST: '_st8', // session storage
  URL_BEFR_RGST: 'rg3',   // session storage

  FNGRPRNT_PLUS: 'tp', // local storage
  USER_LOCATION: 'tl',       // local storage
  USER_TIMEZONE: 'tz',       // local storage
  IS_FROM_VUE: 'isfv',       // local storage

  TOGL_OPEN_README_AT_RGST: 'tg2',
  ALL_WORK_CNT: '_awc1',
  PIN_AUTH_INFO: 'p3',
  USER_LANG: 't9'
}

const StatusCode = {
  Unauthorized: 401,
  Forbidden: 403,
  TooManyRequests: 429,
  InternalServerError: 500
}

const AsptRtioFluxS = {
  ratio: '1:1',
  pixl: '1024x1024'
}

const AsptRtioDaleImg = [
  { ratio: '9:16' },
  { ratio: '1:1', },
  { ratio: '16:9' },
]

const AsptRtioSdxlImg = [
  { ratio: '9:21', },
  { ratio: '9:16' },
  { ratio: '2:3', },
  { ratio: '3:4', },
  { ratio: '1:1', },
  { ratio: '4:3', },
  { ratio: '3:2', },
  { ratio: '16:9' },
  { ratio: '21:9' }
]

const AsptRtioKlingImg = [
  { ratio: '9:16' },
  { ratio: '2:3', },
  { ratio: '3:4', },
  { ratio: '1:1', },
  { ratio: '4:3', },
  { ratio: '3:2', },
  { ratio: '16:9' },
]

const AsptRtioLumaImg = [
  { ratio: '9:21', },
  { ratio: '9:16' },
  { ratio: '3:4', },
  { ratio: '1:1', },
  { ratio: '4:3', },
  { ratio: '16:9' },
  { ratio: '21:9' }
]

const SdxlWdthHegtArr = [
  [640, 1536],
  [768, 1344],
  [832, 1216],
  [896, 1152],
  [1024, 1024],
  [1152, 896],
  [1216, 832],
  [1344, 768],
  [1536, 640]
]

const BgImgUrls = [
  '/aset/img/sgin/bg-sign-in-default-1.avif',
  '/aset/img/sgin/bg-sign-in-default-2.avif',
  '/aset/img/sgin/bg-sign-in-default-3.avif',
]

const SrchPerd = {
  LAST_MONTH: "SHPD0000",
  LAST_WEEK: "weekly",
  OVERALL: "all-time",
}

const SpldSlde = {
  HORZ_HEGT_SM: "100px",
  FIXD_HEGT_XS: "85px",
  FIXD_WDTH_XS : "123px",
  DIR_HORZ    : "ltr",
  DIR_VERT    : "ttb"
}

const NotiClas = {
  MAIN_BNNR: "NTCS0001",
  NOTI_ALRM: "NTCS0002",
  NOTI_TRGT: "NTCS9999"
}

const NotiType = {
  PAOT_CPLT_PAPL: "NTTP0001",
  PAOT_CPLT_STRP: "NTTP0002",
  PAOT_RQST: "NTTP0003",
  PAOT_APRV: "NTTP0010",

  WORK_APRV: "NTTP0011",
  NO_FRMT: "NTTP0012",
  CRTR_GRAD_UPDT: "NTTP0013",
  WORK_REWD: "NTTP0014",
  WORK_REJT: "NTTP0015"
}

const CTNT_TYPE_JSON = "application/json;charset=utf-8"
const CTNT_TYPE_MLTYPART = "multipart/form-data"

const AuthPvdr = {
  GOOGLE: 'g',
  FACEBOOK: 'f',
  MICROSOFT: 'm',
  EMAIL: 'e',
}

const FcbkCnst = {
  APP_ID: import.meta.env.VITE_APP_FACEBOOK_APP_ID,
  GRAPH_API_VER: 'v20.0',
  USER_FIELDS: "id,name,email,picture,first_name,last_name,birthday,age_range,address,about,cover,verified"
}

const UserStat = {
  IN_REQUEST: 'USTT0000',
  ACTIVE: 'USTT0001',
  DORMANCY: 'USTT0002',
  WITHDRAWAL: 'USTT0003',
  BANNED: 'USTT0004',
  LOCKED: 'USTT0005',
  NOT_FOUND: 'USTT0006',
  TOKN_EXPIRED: 'USTT0007', // JWT Token Expired
  NO_AUTH: 'USTT0008', // Not authorized
  WRONG_PW: 'USTT0009',
  EXIST_SAME: 'USTT0010',
  EXIST_DIFF: 'USTT0011',
  BEFR_EMAL_CNFM: "USTT0012",
  SRVR_ERR: "USTT0099"
}

const PaotTrnsStat = {
  TRNX_OKAY: 'TFST0000',
  RQST_APRV: 'TFST0001',
  TRNX_PEND: 'TFST0002',
  TRNX_FALD: 'TFST0003',
  TRNX_PRTL_FALD: 'TFST0004',
  TRNX_CNCL: 'TFST0005',
  NO_WLETFUND: 'TFST0009',
  FALD_SENT: 'TFST0010'
}

const PcseStat = {
  PAID_NO_CNFM: 'PCST0000',
  PAID_CNFM: 'PCST0001',
  PAID_RFND: 'PCST0002',
  IN_DISPUTE: 'PCST0003',
  CANCELED: 'PCST0004',
  UNKNOWN: 'PCST0005'
}

// ref. https://naver.github.io/egjs-infinitegrid/ko/docs/api/JustifiedInfiniteGridOptions
const justGridOptn = {
  gap: 2,
  percentage: false,
  columnRange: [1, 8],
  rowRange: [2, 4],
  useTransform: false,
  sizeRange: [112, 190],
  isCroppedSize: false, // or true
  displayedRow: -1,
  isConstantSize: false,
  useResizeObserver: false,
  observeChildren: false
}

const OrderBy = {
  LATEST: 'AORD0000', // default
  RANDOM: 'AORD0008',
  MATCH: 'AORD0005'
  // , RECOMMEND: "AORD0001"
  // , PURCHASED: "AORD0003"
  // , FAVORED:   "AORD0004"
  // , EXPENSIVE: "AORD0006"
}

const ContentGrade = {
  EVERYONE: 'MAAG0000',
  TEEN: 'MAAG0001',
  ADULT: 'MAAG0002',
  ADULT_P: 'MAAG0003'
}

const Creator = {
  NO_CREATR: 'CTGD0000',
  LEVEL_1: 'CTGD0001',
  LEVEL_12: 'CTGD0012'
}

const ArtRgstStep = {
  REQUESTED: 'RGMJ0000',
  APPROVED: 'RGMJ0001',
  REJECTED: 'RGMJ0002',
  SELF_APRV: 'RGMJ0003',
  IN_REVIEW: 'RGMJ0007',
  WORKING_1: 'RGMJ0008',
  WORKING_2: 'RGMJ0009'
}

const WletVrfyStat = {
  VRFIED_PAPL: 'USVG0001',
  VRFIED_STRP: 'USVG0002',
  INPROGR_PAPL: 'USVG0003',
  INPROGR_STRP: 'USVG0004'
}

const HEDR_APP_JSON = 'application/json; charset=UTF-8'

const ScaleOptions = [
  { value: '', text: '-- Choose --' },
  { value: 'scaleto', text: 'Scale to' },
  { value: 'scaleby', text: 'Scale by' }
]

const ResizeMode = [
  { value: '', text: '-- Choose --' },
  { value: 'justresize', text: 'Just resize' },
  { value: 'cropandresize', text: 'Crop and resize' },
  { value: 'resizeandfill', text: 'Resize and fill' },
  { value: 'justresizelatentupscale', text: 'Just resize (latent upscale)' }
]
const SdVae = [
  { value: '', text: '-- Choose --' },
  { value: 'novae', text: 'No VAE' },
  { value: 'kl-f8-anime2', text: 'kl-f8-anime2' },
  { value: 'vae-ft-mse-840000-ema-pruned', text: 'vae-ft-mse-840000-ema-pruned' },
  { value: 'naianythingvae', text: 'NAI/Anything VAE' },
  { value: 'mse840000_klf8anime', text: 'mse840000_klf8anime' },
  { value: 'naiblessed', text: 'NAI Blessed' }
]
const FaceRestorationModels = [
  { value: '', text: '-- Choose --' },
  { value: 'none', text: 'None' },
  { value: 'gfpgan', text: 'GFPGAN' },
  { value: 'codeformer', text: 'CodeFormer' }
]

const StrpWletStat = {
  NOT_CPLT: "not_complete",
  RESTRICT: "restricted",
  PENDING: "pending",
  ENABLED: "enabled",
  COMPLETE: "complete"
}

export {
  LOCALHOST_V4,
  LOCALHOST_V6_1,
  LOCALHOST_V6_2,
  NotiClas,
  NotiType,
  StrpWletStat,
  StatusCode,
  ResCd,
  UserStat,
  justGridOptn,
  API_ROOT,
  Creator,
  OrderBy,
  WletVrfyStat,
  PcseStat,
  ArtRgstStep,
  ContentGrade,
  ITEM_PER_PAGE_FO_IMG_LIST,
  INIT_FOOTER_Y,
  MAX_FOOTER_Y,
  HEDR_APP_JSON,
  PaotTrnsStat,
  AuthPvdr,
  BrwsStrgKey,
  FcbkCnst,
  CTNT_TYPE_JSON,
  CTNT_TYPE_MLTYPART,
  SrchPerd,
  AD_TEST_YN,
  BgImgUrls,
  ImgGenPvdr,
  SpldSlde,
  CDNR_MAX_RESO,
  CDNR_MAX_RESO_STR,
  EditType,
  CrdtAmt,
  VidoGenPvdr,
  AsptRtioDaleImg,
  AsptRtioSdxlImg,
  SdxlWdthHegtArr,
  AsptRtioKlingImg,
  AsptRtioLumaImg,
  AsptRtioFluxS
}
